import {
  AnimationSecondDiv,
  AnimationFirstDiv,
  LoaderContainer,
  LoaderItem,
  LoaderText,
} from "./styles";

export const Loader = () => (
  <LoaderContainer>
    <LoaderItem>
      <AnimationFirstDiv />
      <AnimationSecondDiv />
    </LoaderItem>
    <LoaderText>Loading is in progress...</LoaderText>
  </LoaderContainer>
);
