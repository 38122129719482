import styled from "styled-components";
import { floatingCategoriesPanelConfigHandlers } from "../handlers";

const getFloatingCategoriesPanel = ({ defaultHeight, positionY, smallHeight }) => {
    return styled.div`
    max-width: 90%;   
    height: ${defaultHeight}px;

    position: fixed;
    top: ${positionY}px;; 
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    
    border-radius: 20px;
    display: flex;
    list-style: none;
    overflow: hidden;

    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; 
    }

    @media(max-width: 640px) {
        height: ${smallHeight}px;
    }
`;
}

export const FloatingCategoriesPanel = getFloatingCategoriesPanel(floatingCategoriesPanelConfigHandlers);