import styles from "./GameBody.module.css";
import { Error } from "@components";
import { errorTypes } from "@data";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { GameIFrame } from "./children";
import { gameKeysWithMobileSupport } from "@data";
import { useEffect, useState } from "react";

export const GameBody = () => {
  const [searchParams] = useSearchParams();
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const { gameData, getIframeUrl } = useOutletContext();
  const { title, key } = gameData;

  const isDemo = searchParams.get("demo");
  const iframeUrl = getIframeUrl(isDemo);
  const hasMobileSupport = gameKeysWithMobileSupport.includes(key);

  useEffect(() => {
    const handleResize = () => setIsPortrait(window.innerHeight > window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.gameContainer}>
      <div
        className={`${styles.gameWrapper} ${!hasMobileSupport && isPortrait ? styles.hiddenGameWrapper : ""}`}
      >
        <GameIFrame src={iframeUrl} title={title} />
      </div>
      {!hasMobileSupport && (
        <div className={styles.flipNotification}>
          <Error errorType={errorTypes.FLIP_YOUR_DEVICE} />
        </div>
      )}
    </div>
  );
};
