import { Error, Loader } from "@components";
import { useOutletContext } from "react-router-dom";
import { GameBody } from "./children";
import { errorTypes } from "@data";

export const Game = () => {
  const { gameData, loading, error } = useOutletContext();

  if (loading) return <Loader />;

  if (error) return <Error errorType={errorTypes.GAME_NOT_AVAILABLE} />;

  if (!gameData) return null;

  return <GameBody />;
};
