import styled from "styled-components";
import { floatingCategoriesPanelItemConfigHandler } from "../handlers";

export const FloatingCategoriesPanelItem = styled.li`
    font-family: "Coolvetica", sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: white;
    padding: 15px 20px;
    background-color: black;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover {
        background-color: #FFB800;
        color: black;
    }

    @media(max-width: 640px) {
        font-size: 14px;
    }

    ${floatingCategoriesPanelItemConfigHandler.setActiveStyles(`
        background-color: #FFB800;
        color: black;
    `)}
`;