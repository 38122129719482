import { useEffect, useRef, useState } from "react";
import { GamesListLayoutUtil } from "../utils";

export function useGamesList() {
  const defaultLayoutPattern = GamesListLayoutUtil.getPattern(window.innerWidth);
  const [layoutPattern, setLayoutPattern] = useState(defaultLayoutPattern);
  const isMobileModeRef = useRef(false);

  useEffect(() => {
    function handleResize() {
      const newLayoutPattern = GamesListLayoutUtil.getPattern(window.innerWidth);
      setLayoutPattern(newLayoutPattern);

      isMobileModeRef.current = window.innerWidth <= 960;
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileMode = isMobileModeRef.current;

  return { layoutPattern, isMobileMode };
}
