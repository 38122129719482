import { Wrapper } from "@/styles";
import { GameHeader, Footer } from "@components";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useGameData } from "@hooks";

export function GameLayout() {
  const { id } = useParams();
  const gameDataContext = useGameData();

  const { updateGameDataById, gameData } = gameDataContext;

  useEffect(() => {
    updateGameDataById(id);
  }, []);

  return (
    <>
      <Wrapper>
        <GameHeader gameData={gameData} />
      </Wrapper>
      <Outlet context={gameDataContext} />
      <Wrapper>
        <Footer />
      </Wrapper>
    </>
  );
}
