import { useState } from "react";
import { axiosInstance } from "@api";
import { initialGamesContextData, GAMES_DATA_API_URL } from "@data";
import { GamesUtil } from "@utils";
import { GamesContext } from "./GamesContext";

export const GamesContextProvider = ({ children }) => {
  const [state, setState] = useState(initialGamesContextData);
  const { games } = state;

  const updateState = (state) => {
    setState((prevState) => ({ ...prevState, ...state }));
  };

  const updateGamesData = async (params = {}) => {
    updateState({ loading: true });
    
    try {
      const response = await axiosInstance.get(GAMES_DATA_API_URL, { params });
      const newData = GamesUtil.prepareNewData(response, params);

      updateState(newData);
    } catch (error) {
      updateState({ error: error.message });
    } finally {
      updateState({ loading: false });
    }
  };

  const updateGamesDataBasedOnSearchValue = (searchValue) => {
    const params = searchValue ? { title: searchValue } : {};

    return updateGamesData(params);
  }

  const updateSelectedCategoryGames = (allGames, selectedCategoryKey) => {
    const selectedCategoryGames = GamesUtil.filterByCategory(
      allGames,
      selectedCategoryKey
    );

    updateState({ selectedCategoryGames });
  }

  const setSelectedCategory = (selectedCategoryKey) => {
    updateState({ selectedCategoryKey });
    updateSelectedCategoryGames(games, selectedCategoryKey);
  };

  const setGamesIsLoading = () => {
    updateState({ loading: true });
  }

  return (
    <GamesContext.Provider
      value={{
        updateGamesData,
        updateGamesDataBasedOnSearchValue,
        setSelectedCategory,
        setGamesIsLoading,
        ...state,
      }}
    >
      {children}
    </GamesContext.Provider>
  );
};
