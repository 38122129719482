export class GamePreviewUtil {
  static getWrapperClassName(styles, layoutKey) {
    const className = layoutKey === "large" ? styles.largeLayout : styles.smallLayout;

    return styles.wrapper + " " + className;
  }

  static scrollTo(element, offset = 0) {
    const elementTop = element.getBoundingClientRect().top;
    const bodyTop = document.body.getBoundingClientRect().top;

    const coordinate = elementTop - bodyTop - offset;

    window.scrollTo({ behavior: "smooth", top: coordinate });
  } 

  static touchIsScroll(touchStartYRef) {
    const deltaY = Math.abs(window.scrollY - touchStartYRef.current);
    return deltaY > 10;
  }
}
