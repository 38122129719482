import styles from "../GamesList.module.css";

export class GamesListUtil {
  static getDarkOverlayClassName(openedPreview, isMobileMode) {
    if (openedPreview !== null && isMobileMode) {
      return `${styles.darkOverlay} ${styles.active}`;
    }

    return styles.darkOverlay;
  }
}
