import { categoriesListItemActiveStyles } from "./categoriesListItemActiveStyles";
import { categoryImageAnimation } from "./categoryImageAnimation";

export const categoriesListItemNotActiveStyles = (images) => `
    background-color: white;

    p {
        color: black;
    }

    .category_image {
        background-image: url(${images.defaultImage});
    }

    &:hover {
    ${categoriesListItemActiveStyles(images)}
    ${categoryImageAnimation}
    }
`;