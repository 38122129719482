import { Fragment } from "react";
import { CategoryListItem } from "./children";

export const CategoryList = ({ activeCategory, categories, changeCategory, onClickAnimation }) => {
    const renderList = () => {
        return categories.map((categoryItem, i) => {
            return (
                <CategoryListItem
                    key={i}
                    changeCategory={() => changeCategory(categoryItem)}
                    onClickAnimation={onClickAnimation}
                    category={categoryItem}
                    isActive={activeCategory === categoryItem}
                />
            );
        });
    };

    return (
        <Fragment>
            {renderList()}
        </Fragment>
    );
};
