import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "reset-css";
import "./assets/fonts/fonts.css";
import App from "./App";
import { AuthContextProvider, GamesContextProvider } from "./context";
// import { reportWebVitals } from "./reportWebVitals";

const app = (
  <AuthContextProvider>
    <GamesContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GamesContextProvider>
  </AuthContextProvider>
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<React.StrictMode>{app}</React.StrictMode>);

/** @TODO Not sure if we need this */
// reportWebVitals();
