import styles from "./GameIFrame.module.css";
import { Loader } from "@components";
import { useState, Fragment } from "react";

export const GameIFrame = ({ src, title }) => {
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <Fragment>
            {loading ? <Loader /> : null}
            <iframe
                className={styles.gameIFrame}
                style={{ visibility: loading ? "hidden" : "visible" }}
                src={src}
                allowFullScreen={true}
                title={title}
                onLoad={handleLoad}
            ></iframe>
        </Fragment>
    );
};
