import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AppContainer, GlobalStyle } from "./styles";
import { useAuthContext } from "./hooks";
import { Home, Game, NotFound } from "./pages";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { GameLayout } from "./layouts/GameLayout/GameLayout";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const { initAuthToken } = useAuthContext();

  useEffect(() => {
    initAuthToken(window.location.search);
  }, []);

  return (
    <AppContainer>
      <GlobalStyle />

      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/:type/:id" element={<GameLayout />}>
          <Route index element={<Game />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContainer>
  );
}

export default App;
