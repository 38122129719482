export class EnvUtil {
  static getApiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  static getStaticFilesUrl() {
    return process.env.REACT_APP_STATIC_URL;
  }

  static getGamesBaseUrl(isDemo) {
    return isDemo
      ? process.env.REACT_APP_DEMO_GAMES_BASE_URL
      : process.env.REACT_APP_GAMES_BASE_URL;
  }
}
