export const mainHeaderTitleSliderJoystickConfigs = {
    screenWidthBreakingPoint: 1080,
    defaultSizes: {
        width: 92,
        height: 77,
    },
    smallSizes: {
        width: 61,
        height: 51,
    }
};
