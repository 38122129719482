import { useGamePreviewImage } from "@hooks";
import Skeleton from 'react-loading-skeleton';
import styles from "./GamePreview.module.css";
import { GamePreviewOverlay } from "./children";
import { GamePreviewUtil } from "./utils";
import { useRef } from "react";
import { newLabel } from "@assets";
import { newGamesKeys } from "@data";

export function GamePreview({
  playUrls,
  title,
  gameKey,
  imagePath,
  layout,
  category,
  description,
  onOpenOverlayPreview,
  onCloseOverlayPreview,
  showOverlayPreview,
  isMobileMode,
}) {
  const { imageRef, imageLoaded, setImageLoaded } = useGamePreviewImage(imagePath);
  const thumbnailCardRef = useRef();
  const touchStartYRef = useRef(0);

  const wrapperClassName = GamePreviewUtil.getWrapperClassName(styles, layout);
  const skeletonStyle = { borderRadius: "20px", minHeight: "200px" };

  const cardWrapperDisplay = imageLoaded ? "inline" : "none";
  const cardWrapperStyle = { display: cardWrapperDisplay };

  const isGameNew = newGamesKeys.includes(gameKey);

  const handleImageWrapperTouchEnd = () => {
    if (!isMobileMode) return;

    if (GamePreviewUtil.touchIsScroll(touchStartYRef)) return;

    onOpenOverlayPreview();

    GamePreviewUtil.scrollTo(thumbnailCardRef.current, 30);
  };

  const handleImageWrapperMouseMove = () => {
    if (isMobileMode) return;
    onOpenOverlayPreview();
  };

  const handlePreviewOverlayMouseLeave = () => {
    if (isMobileMode) return;
    onCloseOverlayPreview();
  }

  const handleTouchStart = () => {
    touchStartYRef.current = window.scrollY;
  }

  return (
    <div className={wrapperClassName}>
      {!imageLoaded && <Skeleton height="100%" style={skeletonStyle} />}
      <div style={cardWrapperStyle}>
        <div
          ref={thumbnailCardRef}
          className={styles.thumbnailCard}
        >
          <GamePreviewOverlay
            categoryKey={category.title}
            playUrls={playUrls}
            title={title}
            description={description}
            onMouseLeave={handlePreviewOverlayMouseLeave}
            onClose={onCloseOverlayPreview}
            show={showOverlayPreview}
          />
          <div
            className={styles.imageWrapper}
            onMouseMove={handleImageWrapperMouseMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleImageWrapperTouchEnd}
            onClick={() => onOpenOverlayPreview()}
          >
            <img
              className={styles.image}
              src={imagePath}
              ref={imageRef}
              onLoad={() => setImageLoaded(true)}
            />
            {
              isGameNew ?
                <img
                  src={newLabel}
                  className={styles.newLabel} />
                : null
            }
          </div>
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
}
