import styles from "./Footer.module.css";

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>© {currentYear}. Profairgames. All rights reserved</div>
      <img
        src="/images/certification/rng-itech-labs-certified.png"
        alt="RNG Certification"
      />
    </div>
  );
}
