import { CategoriesListItem } from "./styled"
import { categoriesData } from "@data";


export const CategoryListItem = ({ category, isActive, changeCategory, onClickAnimation }) => {
    const { label, images } = categoriesData[category];
    const $config = { images, isActive };

    const handleClick = () => {
        changeCategory();
        onClickAnimation();
    };

    return (
        <CategoriesListItem
            $config={$config}
            onClick={handleClick}>
            <div className="category_image"></div>
            <p>{label}</p>
        </ CategoriesListItem>
    )
};
