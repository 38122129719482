import { useState, useRef } from "react";
import { useGamesContext } from "@hooks";
import { categoriesData } from "@data";
import { CategoryList, FloatingCategoryPanel } from "./children";
import styles from "./Category.module.css"

export const Category = ({ categories }) => {
  const { setSelectedCategory } = useGamesContext();
  const [activeCategory, setActiveMenu] = useState(categoriesData.all.id);
  const categoryListContainerRef = useRef(null);

  const changeCategory = (categoryItem) => {
    if (categoryItem === activeCategory) return;

    setActiveMenu(categoryItem);
    setSelectedCategory(categoryItem);
  };

  const onClickAnimation = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div className={styles.category_container}>
      <div className={styles.category_list_container} ref={categoryListContainerRef}>
        <CategoryList
          activeCategory={activeCategory}
          categories={categories}
          changeCategory={changeCategory}
          onClickAnimation={onClickAnimation} />
      </div>
      <FloatingCategoryPanel
        activeCategory={activeCategory}
        categories={categories}
        changeCategory={changeCategory}
        onClickAnimation={onClickAnimation}
        ref={categoryListContainerRef} />
    </div>
  );
};
