import { styled } from "styled-components";

export const MainHeaderTitleText = styled.div`
    font-family: "Coolvetica", sans-serif;
    font-weight: 500;
    padding: 0 20px;
    font-size: 77px;
    @media(max-width: 1080px) {
        font-size: 46px;
        padding: 0 15px;
    }
`;