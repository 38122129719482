import { FloatingCategoriesPanelItem } from "./styled";
import { categoriesData } from "@data";

export const FloatingCategoryPanelItem = ({ category, isActive, changeCategory, onClickAnimation }) => {
    const label = categoriesData[category].label;

    const handleClick = () => {
        changeCategory();
        onClickAnimation();
    };

    return (
        <FloatingCategoriesPanelItem
            $isActive={isActive}
            onClick={handleClick}>
            <p>{label}</p>
        </FloatingCategoriesPanelItem>
    )
};
