import styles from "./SearchIcon.module.css";

export function SearchIcon({ onClick }) {
  return (
    <div className={styles.searchIconWrapper} onClick={onClick}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.searchIcon}
        alt="Search"
      >
        <g clipPath="url(#clip0_165_3006)">
          <circle
            cx="11"
            cy="10"
            r="8.5"
            className={styles.searchIconElement}
            strokeWidth="3"
          />
          <line
            x1="0.991952"
            y1="20.1393"
            x2="4.36037"
            y2="16.7709"
            className={styles.searchIconElement}
            strokeWidth="3"
          />
        </g>
        <defs>
          <clipPath id="clip0_165_3006">
            <rect width="21" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
