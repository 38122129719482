import { useState } from "react";
import { useAuthContext, useGamesList } from "@hooks";
import { GamesListLayoutUtil, GamesUtil } from "@utils";
import { SkeletonTheme } from "react-loading-skeleton";
import { GamePreview } from "../GamePreview";
import styles from "./GamesList.module.css";
import { GamesListUtil } from "./utils";

export function GamesList({ games }) {
  const { authToken } = useAuthContext();
  const { layoutPattern, isMobileMode } = useGamesList();
  const [openedPreview, setOpenedPreview] = useState(null);

  const handleDarkOverlayTouch = (event) => {
    event.stopPropagation();

    setOpenedPreview(null);
  };

  const handleDarkOverlayClick = (event) => {
    event.stopPropagation();

    setOpenedPreview(null);
  };

  const handleOpenPreview = (index) => {
    setOpenedPreview(index);
  };

  const handleDarkOverlayMouseEnter = () => {
    if (isMobileMode) return;

    setOpenedPreview(null);
  }

  const gamePreviews = games.map((game, index) => {
    const { id, key, category, title, description } = game;

    const layoutKey = GamesListLayoutUtil.getKey(layoutPattern, index);
    const playUrls = GamesUtil.getPlayUrls(id, category.title, authToken);
    const imagePath = GamesUtil.getImagePath(key, layoutKey);
    const showOverlayPreview = openedPreview === index;

    return (
      <GamePreview
        key={index}
        title={title}
        gameKey={key}
        description={description}
        playUrls={playUrls}
        imagePath={imagePath}
        layout={layoutKey}
        category={category}
        isMobileMode={isMobileMode}
        onOpenOverlayPreview={() => handleOpenPreview(index)}
        onCloseOverlayPreview={() => handleOpenPreview(null)}
        showOverlayPreview={showOverlayPreview}
      />
    );
  });

  const darkOverlayClassName = GamesListUtil.getDarkOverlayClassName(openedPreview, isMobileMode);

  return (
    <SkeletonTheme baseColor="#DCDCDC" highlightColor="#F1F1F1">
      <div
        className={darkOverlayClassName}
        onTouchEnd={handleDarkOverlayTouch}
        onClick={handleDarkOverlayClick}
        onMouseMove={handleDarkOverlayMouseEnter}
      ></div>
      <div className={styles.wrapper}>{gamePreviews}</div>
    </SkeletonTheme>
  );
}
