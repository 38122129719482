import { BackButton } from "@components";
import { errorsData } from "@data";
import styles from "./Error.module.css"

export const Error = ({ errorType }) => {
  const { errorImage, errorText, backButton } = errorsData[errorType];

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorWrapper}>
        <div className={styles.errorImage}>
          <img src={errorImage} alt="Error Image" />
        </div>
        <div className={styles.errorText}>
          {errorText.map((text, i) => <p key={i}>{text}</p>)}
        </div>
        {backButton ?
          <BackButton linkPath={backButton.path} buttonText={backButton.text} /> : null
        }
      </div>
    </div>
  );
};
