import styled from "styled-components";
import { infiniteSliderLineContainerConfigHandlers } from "../handlers";


function getInfiniteSliderLineContainer({ defaultWidth, smallWidth, durationInSeconds, screenWidthBreakingPoint }) {
    return styled.div`
    padding: 0;
    margin: 0;
    width: ${defaultWidth}px;
    display: flex;
    flex-wrap: nowrap;
    animation: move ${durationInSeconds}s infinite linear;

    @keyframes move {
        to {
            transform: translateX(-50%);
        }
    }

    @media(max-width: ${screenWidthBreakingPoint}px) {
        width: ${smallWidth}px;
    }
`;
}

export const InfiniteSliderLineContainer = getInfiniteSliderLineContainer(infiniteSliderLineContainerConfigHandlers);