import styled, { createGlobalStyle } from "styled-components";

export const AppContainer = styled.div`
  min-height: 100vh;
`;
export const Wrapper = styled.div`
  max-width: 1860px; 
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  @media screen and (max-width: 320px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const GlobalStyle = createGlobalStyle`
    body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  min-width: 320px;
  overflow-x: hidden;
  transition: all 0.2s linear;
  --loader-size: 100px;
  --animation-size: 92px;
  --slot-landscape-height:clamp(200px, 80vh, 50vw);
  --slot-portrait-height:clamp(400px, 80vh, 75vw);
  --all-game-basic-width: clamp(300px, 65vw, 1300px);
  background: var(--theme-page-background);
  color: var(--theme-page-text);
  
}
@media screen and (max-width: 320px) {

overflow-x: unset;
}
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
monospace;
}
* {
box-sizing: border-box;
}
`;
