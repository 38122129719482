import React from "react";
import { ErrorHeader, Error, Footer } from "@components";
import { Wrapper } from "@/styles";
import { errorTypes } from "@data";

export function NotFound() {
  return (
    <Wrapper>
      <ErrorHeader />
      <Error errorType={errorTypes.PAGE_NOT_FOUND} />
      <Footer />
    </Wrapper>
  )
}
