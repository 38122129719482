import {
  MainHeaderTitleImage,
  MainHeaderTitleText,
} from "./styled";
import { mainHeaderTitleSliderJoystickConfigs, mainHeaderTitleSliderStarConfigs } from "@data";
import { joystick, star } from "@assets";

export function MainHeaderTitle() {
  return (
    <>
      <MainHeaderTitleText>Welcome to ProFair Games </MainHeaderTitleText>
      <MainHeaderTitleImage src={joystick} $configs={mainHeaderTitleSliderJoystickConfigs} alt="joystick"></MainHeaderTitleImage>
      <MainHeaderTitleText> Play first, work later </MainHeaderTitleText>
      <MainHeaderTitleImage src={star} $configs={mainHeaderTitleSliderStarConfigs} alt="star"></MainHeaderTitleImage>
    </>
  );
}
