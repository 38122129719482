import { Logo } from "@components";
import { styles } from "../styles";
import { memo } from "react";

export const ErrorHeader = memo(() => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.logo}><Logo /></div>
        </div>
    );
});
