import styled, { keyframes } from "styled-components";

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
`;

export const LoaderItem = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
`;

export const LoaderText = styled.p`
  font-size: 20px;
  font-family: "Coolvetica", sans-serif;
  font-weight: 300;
  font-style: italic;
`;
export const LoaderAnimation = keyframes`
     0% {
        top: 46px;
        left: 46px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: var(--animation-size);
        height: var(--animation-size);
        opacity: 0;
    }
`;

export const AnimationFirstDiv = styled.div`
  position: absolute;
  border: 4px solid var(--loader-color);
  opacity: 1;
  border-radius: 50%;
  animation: ${LoaderAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

export const AnimationSecondDiv = styled(AnimationFirstDiv)`
  animation-delay: -0.5s;
`;
