export const categoryImageAnimation = `
    .category_image {
        animation: rotateAnimation 3s;
    }

    @keyframes rotateAnimation {
        0% {
            transform: rotate(0deg);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
        100% {
            transform: rotate(360deg);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        }
    }
`;