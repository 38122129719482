import {
  InfiniteSliderLineWrapper,
  InfiniteSliderLineContainer,
  InfiniteSliderLineContent,
} from "./styled";

export const InfiniteSliderLine = ({ configs, children }) => {
  return (
    <InfiniteSliderLineWrapper>
      <InfiniteSliderLineContainer $configs={configs}>
        <InfiniteSliderLineContent $configs={configs}>
          {children}
        </InfiniteSliderLineContent>
        <InfiniteSliderLineContent $configs={configs}>
          {children}
        </InfiniteSliderLineContent>
      </InfiniteSliderLineContainer>
    </InfiniteSliderLineWrapper>
  );
};
