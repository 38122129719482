export class DebounceUtil {
  static create(passedFunction, delay) {
    let timeoutId;
    
    return function(...args) {
      clearTimeout(timeoutId);
      
      timeoutId = setTimeout(() => {
        passedFunction.apply(this, args);
      }, delay);
    };
  }
}
