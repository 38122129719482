export class HeaderSearchStylesUtil {
  static getContainerStyles(styles, searchIsOpen) {
    let containerStyles = styles.container;
    
    if (searchIsOpen) {
      containerStyles += ` ${styles.isOpen}`;
    }

    return containerStyles;
  }
}
