import { DEFAULT_CATEGORY } from "../data/constants";
import { EnvUtil } from "./EnvUtil";

export class GamesUtil {
  static filterByCategory(games, categoryKey) {
    if (categoryKey === DEFAULT_CATEGORY) return games;

    return games.filter((game) => game.category.title === categoryKey);
  }

  static prepareNewData(response, params) {
    const { games, categories } = response.data.data.gameData;
    const { title } = params;

    return {
      games,
      categories,
      selectedCategoryKey: DEFAULT_CATEGORY,
      selectedCategoryGames: games,
      searchValue: title ?? "",
      error: null,
    };
  }

  static getPlayUrls(gameId, categoryKey, authToken) {
    let normal;

    if (authToken) {
      normal = `/${categoryKey}/${gameId}?authToken=${authToken}`;
    }

    return {
      demo: `/${categoryKey}/${gameId}?demo=true`,
      normal,
    };
  }

  static getImagePath(gameKey, layoutKey) {
    const staticFilesUrl = EnvUtil.getStaticFilesUrl();

    return `${staticFilesUrl}static/preview/${layoutKey}/${gameKey}.png`;
  }
}
