import { Logo } from "@components";
import mainHeaderStyles from "./MainHeader.module.css"
import { memo, useState } from "react";
import { MainHeaderTitleSlider, MainHeaderSearch } from "./children";

export const MainHeader = memo(() => {
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const wrapperClassName = searchIsOpen ? mainHeaderStyles.searchOpened : "";

  const handleLogoClick = () => {
    setSearchIsOpen(false);
  };

  return (
    <div className={wrapperClassName}>
      <div className={mainHeaderStyles.mainHeaderContainer}>
        <div className={mainHeaderStyles.logo} onClick={handleLogoClick}><Logo /></div>
        <div className={mainHeaderStyles.mainHeaderTitleSlider}><MainHeaderTitleSlider /></div>
        <MainHeaderSearch
          searchIsOpen={searchIsOpen}
          setSearchIsOpen={setSearchIsOpen}
        />
      </div>
    </div>
  );
});
