import { mainHeaderTitleSliderConfigs } from "@data";
import { InfiniteSliderLine } from "@components";
import { MainHeaderTitle } from "../MainHeaderTitle";
import { MainHeaderTitleSliderWrapper } from "./styled";

export function MainHeaderTitleSlider() {
  return (
    <MainHeaderTitleSliderWrapper>
      <InfiniteSliderLine configs={mainHeaderTitleSliderConfigs}>
        <MainHeaderTitle />
      </InfiniteSliderLine>
    </MainHeaderTitleSliderWrapper>
  );
}
