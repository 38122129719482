import React, { forwardRef, useEffect, useState } from "react";
import { FloatingCategoryPanelItem } from "./children";
import { FloatingCategoriesPanel } from "./styled";
import { FloatingCategoryPanelStylesUtil } from "./utils";
import { floatingCategoryPanelConfig } from "@data";

export const FloatingCategoryPanel = forwardRef(({ activeCategory, categories, changeCategory, onClickAnimation }, categoryListContainerRef) => {
    const [positionY, setPositionY] = useState(floatingCategoryPanelConfig.minPositionY);

    const setNewPositionY = () => {
        const newPositionY = categoryListContainerRef.current ?
            FloatingCategoryPanelStylesUtil.calculatePositionY(
                categoryListContainerRef,
                positionY,
                floatingCategoryPanelConfig
            ) : floatingCategoryPanelConfig.minPositionY;
        setPositionY(newPositionY);
    }

    useEffect(() => {
        window.addEventListener("scroll", setNewPositionY);
        return () => {
            window.removeEventListener("scroll", setNewPositionY);
        };
    }, []);

    const renderList = () => {
        return categories.map((categoryItem, i) => {
            return (
                <FloatingCategoryPanelItem
                    key={i}
                    changeCategory={() => changeCategory(categoryItem)}
                    onClickAnimation={onClickAnimation}
                    category={categoryItem}
                    isActive={activeCategory === categoryItem}
                />
            );
        });
    };

    const $config = {
        ...floatingCategoryPanelConfig,
        positionY
    };

    return (
        <FloatingCategoriesPanel $config={$config}>
            {renderList()}
        </FloatingCategoriesPanel>
    );
});
