import { gamesListLayoutPatternConfigs } from "@data";

export class GamesListLayoutUtil {
  static getPattern(width) {
    const { smallScreen, largeScreen } = gamesListLayoutPatternConfigs;

    if (width <= 960) {
      return smallScreen;
    }

    return largeScreen;
  }

  static getKey(layoutPattern, gameIndex) {
    const layoutIndex = gameIndex % layoutPattern.length;
    return layoutPattern[layoutIndex];
  }
}
