import { useCallback, useEffect, useState } from "react";
import { DebounceUtil } from "../utils";
import { useGamesContext } from "./useGamesContext";

export function useHeaderSearch(searchIsOpen) {
  const { updateGamesDataBasedOnSearchValue, setGamesIsLoading } = useGamesContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    if (!searchIsOpen && searchInputValue) {
      setSearchInputValue("");
      debounceSearchGames("");
      setGamesIsLoading();
    }
  }, [searchIsOpen]);

  const debounceSearchGames = useCallback(
    DebounceUtil.create((value) => {
      updateGamesDataBasedOnSearchValue(value);
    }, 750),
    []
  );

  return { searchInputValue, setSearchInputValue, debounceSearchGames };
}
