export class FloatingCategoryPanelStylesUtil {
    static calculatePositionY(categoryListContainerRef, currentPositionY, { scrollBreakpoint, maxPositionY, minPositionY }) {
        const categoryListContainerY = categoryListContainerRef.current.getBoundingClientRect().top;
        switch (true) {
            case categoryListContainerY <= scrollBreakpoint:
                return maxPositionY;
            case categoryListContainerY < 0:
                return currentPositionY - categoryListContainerY;
            case categoryListContainerY >= 0:
                return minPositionY;
        }
    }
}