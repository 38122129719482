import { useState } from "react";
import { axiosInstance } from "../api";
import { useAuthContext } from "../hooks";
import { EnvUtil } from "../utils";

export function useGameData() {
  const { authToken } = useAuthContext();

  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateGameDataById = async (id) => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(`/lobbyGames/${id}`);
      const { gameData } = response.data.data;
      setGameData(gameData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getIframeUrl = (isDemo) => {
    if (!gameData) return "";

    const gamesBaseUrl = EnvUtil.getGamesBaseUrl(isDemo);
    const { category, key } = gameData;

    const url = `${gamesBaseUrl}${category.title}/${key}`;

    if (authToken) {
      return `${url}?authToken=${authToken}`;
    }

    return url;
  }

  return { gameData, loading, error, updateGameDataById, getIframeUrl };
}
