export const categoriesListItemActiveStyles = (images) => `
    background-color: black;

    p {
        color: white;
    }
    
    .category_image {
        background-image: url(${images.activeImage});
    }
`;