import { useState } from "react";
import { initialAuthContextData } from "@data";
import { AuthUtil } from "../../utils";
import { AuthContext } from "./AuthContext";

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState(initialAuthContextData);

  const updateState = (state) => {
    setState((prevState) => ({ ...prevState, ...state }));
  };

  const initAuthToken = (queryString) => {
    const authToken = AuthUtil.getToken(queryString);

    if (authToken) {
      updateState({ authToken });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        initAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
