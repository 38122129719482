import { commonError, notFoundError, flippedSmartphone } from "@assets";

export const errorsData = {
    pageNotFound: {
        errorImage: notFoundError,
        errorText: ["This page does not exist"],
        backButton: {
            path: "/",
            text: "To games",
        }
    },
    noSearchResult: {
        errorImage: commonError,
        errorText: [
            "Nothing found",
            "for your request"
        ],
        isCentered: false,
    },
    gameNotAvailable: {
        errorImage: commonError,
        errorText: ["Something went wrong, please try again later"],
        backButton: {
            path: "/",
            text: "To games",
        }
    },
    gamesListFailedLoading: {
        errorImage: commonError,
        errorText: ["Something went wrong, please try again later"],
    },
    flipYourDevice: {
        errorImage: flippedSmartphone,
        errorText: [
            "Flip your device",
            "for comfortable",
            "gaming"
        ]
    }
}