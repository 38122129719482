import styled from "styled-components";
import { infiniteSliderLineContainerConfigHandlers } from "../handlers";


function getInfiniteSliderLineContent({ defaultWidth, smallWidth, screenWidthBreakingPoint }) {
    return styled.div`
    display: flex;
    align-items: center;
    width: ${defaultWidth}px;
    @media(max-width: ${screenWidthBreakingPoint}px) {
        width: ${smallWidth}px;
    }
`;
}

export const InfiniteSliderLineContent = getInfiniteSliderLineContent(infiniteSliderLineContainerConfigHandlers);