import styles from "./MainHeaderSearch.module.css";
import { useHeaderSearch } from "@hooks";
import { SearchIcon } from "./children";
import { HeaderSearchStylesUtil } from "./utils";

export function MainHeaderSearch({ searchIsOpen, setSearchIsOpen }) {
  const { searchInputValue, setSearchInputValue, debounceSearchGames } = useHeaderSearch(searchIsOpen);

  const handleIconClick = () => {
    setSearchIsOpen((prevState) => !prevState);
  };

  const handleSearchInputChange = (event) => {
    const newInputValue = event.target.value;

    setSearchInputValue(newInputValue);
    debounceSearchGames(newInputValue);
  };

  const containerStyles = HeaderSearchStylesUtil.getContainerStyles(styles, searchIsOpen);

  return (
    <div className={containerStyles}>
      {searchIsOpen && (
        <input
          type="text"
          value={searchInputValue}
          onChange={handleSearchInputChange}
          className={styles.input}
          placeholder="Enter game name:"
        />
      )}
      <SearchIcon onClick={handleIconClick} />
    </div>
  );
}
