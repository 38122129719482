import styled from "styled-components";
import { categoriesListItemConfigHandlers } from "../handlers";
import { categoriesListItemActiveStyles } from "./categoriesListItemActiveStyles";
import { categoriesListItemNotActiveStyles } from "./categoriesListItemNotActiveStyles";

export const CategoriesListItem = styled.li`
    font-family: "Coolvetica", sans-serif;
    font-weight: 500;
    border: solid 2px #000;
    border-radius: 20px;
    flex-shrink: 0;
    display: flex;

    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding: 10px;
    font-size: 17px;
    text-align: center;

    p {
        padding-left: 20px;
    }

    .category_image {
        width: 20px;
        height: 20px;
    }

    ${categoriesListItemConfigHandlers.setStylesForActiveAndNotActiveStatus(
    categoriesListItemActiveStyles,
    categoriesListItemNotActiveStyles
)}
`;