import { memo } from "react";
import { Link } from "react-router-dom";
import { backArrow } from "@assets";
import styles from "./BackButton.module.css";

export const BackButton = memo(({ linkPath, buttonText }) => {
  return (
    <Link to={linkPath} className={styles.backButton}>
      <img src={backArrow} alt="Back arrow" />
      <p>{buttonText}</p>
    </Link>
  );
});
