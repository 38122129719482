import { Link } from "react-router-dom";
import styles from "./GamePreviewPlayButton.module.css";

export function GamePreviewPlayButton({ path, text }) {
  const enabledClassName = styles.playButton + " " + styles.enabled;
  const disabledClassName = styles.playButton + " " + styles.disabled;

  if (path) {
    return (
      <Link to={path} className={styles.resetLinkStyles}>
        <div className={enabledClassName}>{text}</div>
      </Link>
    );
  }

  return <div className={disabledClassName}>{text}</div>;
}
