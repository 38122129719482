import { useEffect, useRef, useState } from "react";

export function useGamePreviewImage(imagePath) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const { current } = imageRef;

    if (current && current.complete) {
      setImageLoaded(true);
      return;
    }

    setImageLoaded(false);
  }, [imagePath]);

  return { imageLoaded, imageRef, setImageLoaded };
}
