import { memo } from "react";
import { Logo, BackButton } from "@components";
import { styles } from "../styles";
import gameHeaderStyles from "./GameHeader.module.css";

export const GameHeader = memo(({ gameData }) => {
    const gameTitle = gameData ? gameData.title : null;
    return (
        <div className={styles.headerContainer}>
            <div className={styles.logo}><Logo /></div>
            {gameTitle ? <p className={gameHeaderStyles.gameHeaderTitle}>{gameTitle}</p> : null}
            <BackButton linkPath="/" buttonText="Back to games" />
        </div>
    );
});