import { Link } from "react-router-dom";
import { logo } from "@assets";
import { useAuthContext } from "../../hooks";
import { LogoImg } from "./styles";

export const Logo = () => {
  const { authToken } = useAuthContext();

  return (
    <Link to={authToken ? `/?authToken=${authToken}` : "/"}>
      <LogoImg src={logo} alt={"ProFair Logo"}></LogoImg>
    </Link>
  );
};
