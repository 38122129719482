import { styled } from "styled-components";
import { spin } from "@components";
import { mainHeaderTitleImageConfigHandlers } from "../handlers";

function getMainHeaderTitleImage({ defaultWidth, defaultHeight, screenWidthBreakingPoint, smallWidth, smallHeight }) {
    return styled.img`
    width: ${defaultWidth}px;
    height: ${defaultHeight}px;
    animation: ${spin} 5s infinite linear;
    @media(max-width: ${screenWidthBreakingPoint}px) {
        width: ${smallWidth}px;
        height: ${smallHeight}px;
    }
`
}

export const MainHeaderTitleImage = getMainHeaderTitleImage(mainHeaderTitleImageConfigHandlers);
