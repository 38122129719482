import styles from "../GamePreview.module.css";
import { allCategoryWhite } from "@assets";
import { categoriesData } from "@data";
import { GamePreviewPlayButton } from "./GamePreviewPlayButton";

export function GamePreviewOverlay({
  show,
  playUrls,
  title,
  categoryKey,
  description,
  onMouseEnter,
  onMouseLeave,
  onClose,
}) {
  const categoryGameLabel = categoriesData[categoryKey].gameLabel;
  const additionalClassName = show ? styles.showComponent : "";

  return (
    <div 
      className={styles.overlay + " " + additionalClassName} 
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.overlayHead}>
        <img src={allCategoryWhite} alt="Game" className={styles.overlayHeadIcon} />
        <div>{categoryGameLabel}</div>
        <img src="/images/icons/close.svg" className={styles.closeButtonIcon} onClick={onClose} />
      </div>
      <div className={styles.overlayBody}>
        <div className={styles.overlayGameTitle}>
          {title}
        </div>
        <div className={styles.overlayGameDescription}>
          {description}
        </div>
      </div>
      <div className={styles.overlayButtons}>
        <GamePreviewPlayButton text="Play Demo" path={playUrls.demo} />
        <GamePreviewPlayButton text="Play Real" path={playUrls.normal} />
      </div>
    </div>
  );
}
