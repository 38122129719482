export const mainHeaderTitleSliderStarConfigs = {
    screenWidthBreakingPoint: 1080,
    defaultSizes: {
        width: 68,
        height: 69,
    },
    smallSizes: {
        width: 45,
        height: 46,
    }
};
