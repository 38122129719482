import React from "react";
import { Outlet } from "react-router-dom";
import { MainHeader, Footer } from "@components";
import { Wrapper } from "@/styles";

export function MainLayout() {
  return (
    <>
      <Wrapper>
        <MainHeader />
      </Wrapper>
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Wrapper>
        <Footer />
      </Wrapper>
    </>
  );
}
