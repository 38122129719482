import {
    allCategoryWhite,
    allCategoryBlack,
    cardCategoryWhite,
    cardCategoryBlack,
    slotCategoryWhite,
    slotCategoryBlack,
    tableCategoryWhite,
    tableCategoryBlack,
    customCategoryWhite,
    customCategoryBlack
} from "@assets";

export const categoriesData = {
    all: {
        label: "All Games",
        gameLabel: "Game",
        id: "all",
        images: {
            defaultImage: allCategoryBlack,
            activeImage: allCategoryWhite,
        }
    },
    cards: {
        label: "Card Games",
        gameLabel: "Card Game",
        id: "cards",
        images: {
            defaultImage: cardCategoryBlack,
            activeImage: cardCategoryWhite,
        }
    },
    slot: {
        label: "Slot Games",
        gameLabel: "Slot Game",
        id: "slot",
        images: {
            defaultImage: slotCategoryBlack,
            activeImage: slotCategoryWhite,
        }
    },
    table: {
        label: "Table Games",
        gameLabel: "Table Game",
        id: "table",
        images: {
            defaultImage: tableCategoryBlack,
            activeImage: tableCategoryWhite,
        }
    },
    instant: {
        label: "Instant Games",
        gameLabel: "Instant Game",
        id: "instant",
        images: {
            /** @FIX Should be instant icon */
            defaultImage: customCategoryBlack,
            /** @FIX Should be instant icon */
            activeImage: customCategoryWhite,
        }
    },
    custom: {
        label: "Custom Games",
        gameLabel: "Custom Game",
        id: "custom",
        images: {
            defaultImage: customCategoryBlack,
            activeImage: customCategoryWhite,
        }
    }
}